import axios from 'axios';

export const checkCode = async (code: string, email: string | undefined, data1: string | undefined, API_BASE_URL: string, check: boolean) => {
    const CHECK_CODE_ENDPOINT = `${API_BASE_URL}/check-code`;
    if (data1 && email) {
        try{
            if(check){
                const response = await axios.post(CHECK_CODE_ENDPOINT, { code, email, check}, {
                    headers: {
                        'Authorization': data1
                    }
                });
                return response.status;
            }
            const response = await axios.post(CHECK_CODE_ENDPOINT, { code, email }, {
                headers: {
                    'Authorization': data1
                }
            });
            return response.status;
        }catch(error){
          if(axios.isAxiosError(error)){
            return error.response?.status
          }
        }
    }
    return 200
};