import React, { useEffect, useState } from 'react';
import { Box } from "@twilio-paste/core/box";
import { useSelector } from "react-redux";

import { MessagingCanvasPhase } from "./MessagingCanvasPhase";
import { AppState, EngagementPhase } from "../store/definitions";
import { PreEngagementFormPhase } from "./PreEngagementFormPhase";
import { LoadingPhase } from "./LoadingPhase";
import { EntryPoint } from "./EntryPoint";
import { getOuterContainerStyles, getInnerContainerStyles } from "./styles/RootContainer.styles";
import { PhaseIntroText } from './PhaseIntroText';
import ImageSlider from './ImageSlider';

import IbpoLogo from "../assets/img/ibpo_logo.png";
import AnikaAudio from '../assets/audio/hi_anika.mp3';
import sliderImage_1 from '../assets/img/image_slider_01.png'
import sliderImage_2 from '../assets/img/image_slider_02.png'
import sliderImage_3 from '../assets/img/image_slider_03.png'
import sliderImage_4 from '../assets/img/image_slider_04.png'
import sliderImage_5 from '../assets/img/image_slider_05.png'
import sliderImage_6 from '../assets/img/image_slider_06.png'

import sliderMobileImage_1 from '../assets/img/image_slider_mobile/image_slider_01.png'
import sliderMobileImage_2 from '../assets/img/image_slider_mobile/image_slider_02.png'
import sliderMobileImage_3 from '../assets/img/image_slider_mobile/image_slider_03.png'
import sliderMobileImage_4 from '../assets/img/image_slider_mobile/image_slider_04.png'
import sliderMobileImage_5 from '../assets/img/image_slider_mobile/image_slider_05.png'
import sliderMobileImage_6 from '../assets/img/image_slider_mobile/image_slider_06.png'
// import sliderMobileImage_7 from '../assets/img/image_slider_mobile/image_slider_07.png'
// import sliderMobileImage_8 from '../assets/img/image_slider_mobile/image_slider_08.png'
// import sliderMobileImage_9 from '../assets/img/image_slider_mobile/image_slider_09.png'
// import sliderMobileImage_10 from '../assets/img/image_slider_mobile/image_slider_10.png'
// import sliderMobileImage_11 from '../assets/img/image_slider_mobile/image_slider_11.png'
// import sliderMobileImage_12 from '../assets/img/image_slider_mobile/image_slider_12.png'
// import sliderMobileImage_13 from '../assets/img/image_slider_mobile/image_slider_13.png'

const getPhaseComponent = (phase: EngagementPhase) => {
    switch (phase) {
        case EngagementPhase.Loading:
            return <LoadingPhase />;
        case EngagementPhase.MessagingCanvas:
            return <MessagingCanvasPhase />;
        case EngagementPhase.PreEngagementForm:
        case EngagementPhase.Confirmation:
        default:
            return <PreEngagementFormPhase phase={phase} />;
    }
};

const getPhaseText = (phase: EngagementPhase) => {
    return <PhaseIntroText phase={phase} />
    // switch (phase) {
    //     case EngagementPhase.Loading:
    //         return 'Please wait...';
    //     case EngagementPhase.MessagingCanvas:
    //         return "Let's Chat...";
    //     case EngagementPhase.PreEngagementForm:
    //     case EngagementPhase.Confirmation:
    //     default:
    //         return "Hello! I'm Anika";
    // }
}


export function RootContainer() {
    // var audio = new Audio(AnikaAudio)
    // const [audio, SetAudio] = useState<HTMLAudioElement | null>(null);

    // const Playit = () => {
    //     if (audio) {
    //         console.log("Playing Audio")
    //         audio.play();
    //         console.log("Audio Played")
    //     } else {
    //         console.log("Audio Value: ", audio)
    //     }
    // };

    let sliderImageList = [
        sliderImage_1,
        sliderImage_2,
        sliderImage_3,
        sliderImage_4,
        sliderImage_5,
        sliderImage_6,
    ];

    let sliderImageMobileList = [
        sliderMobileImage_1,
        sliderMobileImage_2,
        sliderMobileImage_3,
        sliderMobileImage_4,
        sliderMobileImage_5,
        sliderMobileImage_6,
        // sliderMobileImage_7,
        // sliderMobileImage_8,
        // sliderMobileImage_9,
        // sliderMobileImage_10,
        // sliderMobileImage_11,
        // sliderMobileImage_12,
        // sliderMobileImage_13,
    ];

    const { currentPhase, expanded } = useSelector(({ session }: AppState) => ({
        currentPhase: session.currentPhase,
        expanded: session.expanded
    }));

    const [windowSize, setWindowSize] = useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });

    useEffect(() => {
        // console.log("Audio", audio)
        // SetAudio(new Audio(AnikaAudio));
        // console.log("Set Audio", audio)

        // Playit()

        // alert("Window Size: " + window.innerWidth + " x " + window.innerHeight + "\n" + window.outerWidth + " x " + window.outerHeight);
        const handleResize = () => {
            // console.log("Size Changed")
            setWindowSize({
                height: window.innerHeight,
                width: window.innerWidth,
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const outerContainerStyles = getOuterContainerStyles();
    // console.log("Win Size: ", windowSize, "Expanded: ", expanded)
    const innerContainerStyles = getInnerContainerStyles(windowSize);

    return (
        <Box>
            <Box {...outerContainerStyles}>
                {expanded && (
                    <Box data-test="root-container" {...innerContainerStyles} className="br_30">
                        {currentPhase === EngagementPhase.MessagingCanvas
                            ?
                            <div className="row br_30 lc_chatbox">
                                <div className="col-6 lc_intro_section display_flex align_items_center inherit_height use_bg_parent">
                                    <ImageSlider desktopImages={sliderImageList} mobileImages={sliderImageMobileList} interval={5500} />
                                </div>
                                <div className="col-6 lc_component_section display_flex justify_content_center inherit_height use_bg_parent">
                                    {getPhaseComponent(currentPhase)}
                                </div>
                            </div>
                            :
                            <div className="row br_30 lc_chatbox">
                                <div className="col-6 lc_intro_section display_flex align_items_center inherit_height">
                                    <div className="lc_intro_logo_container"><img className="lc_intro_logo" src={IbpoLogo} alt="IBPO Logo" /></div>

                                    {currentPhase === EngagementPhase.PreEngagementForm && (
                                        <audio autoPlay={true}>
                                            <source src={AnikaAudio} type="audio/mp3"></source>
                                        </audio>
                                    )}

                                    <div className={`lc_intro_text_section ${currentPhase === EngagementPhase.PreEngagementForm && 'bg_anika'}`}>
                                        {getPhaseText(currentPhase)}
                                        {/* <h3 className="text_intro_title">{"Hello! I'm Anika"}</h3> */}
                                    </div>

                                </div>
                                <div className={`col-6 lc_component_section display_flex justify_content_center inherit_height`}>
                                    {/* Try add height, max height and overflow  */}
                                    {/* <div className="overflow-auto"> */}
                                    {getPhaseComponent(currentPhase)}
                                    {/* </div> */}
                                </div>
                            </div>
                        }
                    </Box>
                )}
                <EntryPoint />
            </Box>
        </Box>
    );
}
