import React, { useRef, useEffect, FormEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoIosCheckmark } from "react-icons/io";
import axios from "axios";
import { useTranslation } from "react-i18next";
// Twilio Paste UI components
import { Input, Label, Box, Button, Text } from "@twilio-paste/core";
import { useLocation } from 'react-router-dom';

import { sessionDataHandler } from "../sessionDataHandler";
import { addNotification, changeEngagementPhase, updatePreEngagementData } from "../store/actions/genericActions";
import { initSession } from "../store/actions/initActions";
import { AppState, EngagementPhase } from "../store/definitions";
import { Header } from "./Header";
import { notifications } from "../notifications";
import { NotificationBar } from "./NotificationBar";
import { checkCode } from "./checkTheCode";
import { introStyles, fieldStyles, titleStyles, formStyles } from "./styles/PreEngagementFormPhase.styles";


import "./styles/ConfirmationPhase.css";

/* Sx Add */
import "./styles/customCss.css";

// const API_BASE_URL = 'http://localhost:3001';
// const API_BASE_URL = 'http://192.168.1.35:3001';
const API_BASE_URL = 'https://api-chat.ibpo.com.my';
// const API_BASE_URL = 'http://localhost:3001';
const DELETE_CODE_ENDPOINT = `${API_BASE_URL}/delete-code`;
const SEND_EMAIL_ENDPOINT = `${API_BASE_URL}/sendEmail`;
const SEND_SMS_ENDPOINT = `${API_BASE_URL}/sendSMS`;
const CHECK_VER_USER = `${API_BASE_URL}/check_both`;
const INFORM_USER = "The token does not exist! Try to refresh the page";

export const PreEngagementFormPhase: React.FC<{ phase: EngagementPhase }> = ({ phase }) => {
    const { t, i18n } = useTranslation("global");
    const [verificationCode, setVerificationCode] = useState<string>("");
    const inputsRef = useRef<HTMLInputElement[]>([]);
    const [time, setTime] = useState<number>(60);
    const { name, email, phone } = useSelector((state: AppState) => state.session.preEngagementData) || {};
    const dispatch = useDispatch();
    const [phoneNumberError, setPhoneNumberError] = useState<string | null>(null);
    const data1 = sessionDataHandler.tryResumeExistingSession();
    const [verificationType, setVerificationType] = useState<"phone" | "email">("phone");
    const [otpSent, setOtpSent] = useState<boolean>(false);
    const [resendAttempts, setResendAttempts] = useState<number>(0);
    
    const [refCode, setRefCode] = useState<string | null>(null); // Add state to store ref_code
    
    const location = useLocation(); // Get the location object from react-router-dom
    const searchParams = new URLSearchParams(location.search); // Extract search parameters
    const ref_code = searchParams.get('ref_code'); // Get the ref_code from the URL

    useEffect(() => {
        if (ref_code) {
            setRefCode(ref_code); // Store ref_code in state if present
            console.log(`Ref code detected: ${ref_code}`); // Log for debugging
        }
    }, [ref_code]);

    const startTimer = () => {
        const timer = setInterval(() => {
            setTime((prevTime) => {
                if (prevTime > 0) {
                    return prevTime - 1;
                }
                clearInterval(timer);
                return 0;
            });
        }, 1000);
    };

    const validatePhoneNumber = (input: string) => {
        // Define a regular expression for a valid phone number pattern.
        const phoneNumberPattern = /^\+?[0-9]{10,12}$/; // Allows an optional '+' at the beginning

        if (phoneNumberPattern.test(input)) {
            setPhoneNumberError(null);
            return true;
        }
        setPhoneNumberError(t("phoneNumberError"));
        return false;
    };

    const deleteCodeBeforeUnload = () => {
        if (email && data1?.token) {
            if (verificationType === "phone") {
                axios
                    .post(
                        DELETE_CODE_ENDPOINT,
                        { email: phone, check: true },
                        {
                            headers: {
                                Authorization: data1?.token
                            }
                        }
                    )
                    .catch((error) => {
                        console.error("Error deleting code:", error);
                    });
            } else {
                axios
                    .post(
                        DELETE_CODE_ENDPOINT,
                        { email },
                        {
                            headers: {
                                Authorization: data1?.token
                            }
                        }
                    )
                    .catch((error) => {
                        console.error("Error deleting code:", error);
                    });
            }
        } else {
            dispatch(addNotification(notifications.errorMessage(t('somethingWentWrong'))));
        }
    };

    const handleSuccessfulVerification = (data: any) => {
        dispatch(
            initSession({
                token: data.token,
                conversationSid: data.conversationSid,
                engPhase: EngagementPhase.Confirmation,
                translation: { t }
            })
        );
        setTime(60);
        startTimer();
    };

    const handleVerificationError = (error: any, verificationErrorMessage: string) => {
        dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
        dispatch(addNotification(notifications.errorMessage(verificationErrorMessage)));
        throw error;
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        dispatch(changeEngagementPhase({ phase: EngagementPhase.Loading }));
        const currentLanguage = i18n.language;
        let verificationErrorMessage = "";
        try {
            const data = await sessionDataHandler.fetchAndStoreNewSession({
                formData: {
                    friendlyName: name,
                    email,
                    phone,
                    url: window.location.href.toString(),
                    currentLg: currentLanguage,
                    refCode,
                    query: `${t('helloMessage')} ${name}`,
                    //query: `${email}   ${name}   ${phone}   ${window.location.href.toString()}`
                }
            });

            if (data) {
                await axios
                    .post(
                        CHECK_VER_USER,
                        {
                            email,
                            phone
                        },
                        {
                            headers: {
                                Authorization: data.token
                            }
                        }
                    )
                    .catch((error) => {
                        verificationErrorMessage = t('verificationErrorEmailPhoneMismatch');
                        handleVerificationError(error, verificationErrorMessage);
                        throw error;
                    });
                if (verificationType === "phone") {
                    axios
                        .post(
                            SEND_SMS_ENDPOINT,
                            {
                                phone,
                                name
                            },
                            {
                                headers: {
                                    Authorization: data.token
                                }
                            }
                        )
                        .then(() => {
                            handleSuccessfulVerification(data);
                        })
                        .catch((error) => {
                            console.error(`Error: ${error}`);
                            verificationErrorMessage = t('verificationErrorSMS');
                            dispatch(addNotification(notifications.errorMessage(verificationErrorMessage)));
                            dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
                            throw error;
                        });
                } else {
                    axios
                        .post(
                            SEND_EMAIL_ENDPOINT,
                            {
                                email,
                                name
                            },
                            {
                                headers: {
                                    Authorization: data.token
                                }
                            }
                        )
                        .then(() => {
                            handleSuccessfulVerification(data);
                        })
                        .catch((error) => {
                            verificationErrorMessage = t('verificationErrorEmail');
                            handleVerificationError(error, verificationErrorMessage);
                            throw error;
                        });
                }
            } else {
                dispatch(addNotification(notifications.errorMessage(INFORM_USER)));
            }
        } catch (err) {
            if (verificationErrorMessage === "") {
                dispatch(addNotification(notifications.failedToInitSessionNotification((err as Error).message)));
            }
            dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { value, maxLength } = e.target;
        const trimValue = value.slice(0, maxLength);

        setVerificationCode((prevCode) => {
            const codeArray = prevCode.split("");
            codeArray[index] = trimValue;
            return codeArray.join("");
        });

        if (index < inputsRef.current.length - 1 && trimValue) {
            inputsRef.current[index + 1].focus();
        }
    };

    useEffect(() => {
        if (inputsRef.current && inputsRef.current[0]) {
            inputsRef.current[0].focus();
        }
        startTimer();
    }, []);

    const handleBackspace = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        // Move to the previous input field when backspace is pressed on an empty field
        if (e.key === "Backspace" && index > 0 && !e.currentTarget.value) {
            inputsRef.current[index - 1].focus();
        }
    };

    const handleVerify = async () => {
        dispatch(changeEngagementPhase({ phase: EngagementPhase.Loading }));
        let statusCode;
        if (verificationType === "phone") {
            statusCode = await checkCode(verificationCode, phone, data1?.token, API_BASE_URL, true);
        } else {
            statusCode = await checkCode(verificationCode, email, data1?.token, API_BASE_URL, false);
        }
        // const statusCode = 200;
        switch (statusCode) {
            case 200:
                // Code is correct
                deleteCodeBeforeUnload();
                if (data1?.token) {
                    dispatch(changeEngagementPhase({ phase: EngagementPhase.MessagingCanvas }));
                    return;
                }
                const data = sessionDataHandler.tryResumeExistingSession();
                if (data) {
                    dispatch(
                        initSession({
                            token: data.token,
                            conversationSid: data.conversationSid,
                            engPhase: EngagementPhase.MessagingCanvas,
                            translation: { t }
                        })
                    );
                } else {
                    dispatch(addNotification(notifications.errorMessage(INFORM_USER)));
                    dispatch(changeEngagementPhase({ phase: EngagementPhase.Confirmation }));
                }
                break;
            case 401:
                // Incorrect OTP
                dispatch(addNotification(notifications.errorMessage(t('incorrectOTP'))));
                dispatch(changeEngagementPhase({ phase: EngagementPhase.Confirmation }));
                break;
            case 400:
                // OTP has expired
                dispatch(
                    addNotification(
                        notifications.errorMessage(t('expiredOTP'))
                    )
                );
                dispatch(changeEngagementPhase({ phase: EngagementPhase.Confirmation }));
                break;
            case 402:
                // Document not found
                dispatch(addNotification(notifications.errorMessage(t('documentNotFound'))));
                dispatch(changeEngagementPhase({ phase: EngagementPhase.Confirmation }));
                break;
            default:
                // Handle other status codes
                dispatch(
                    addNotification(
                        notifications.errorMessage(t('codeCheckError'))
                    )
                );
                dispatch(changeEngagementPhase({ phase: EngagementPhase.Confirmation }));
        }
    };

    const handleResend = () => {
        try {
            if (resendAttempts >= 3) {
                notifications.errorMessage(t('maxResendAttemptsExceeded'));
                return;
            }

            if (data1?.token) {
                if (verificationType === "phone") {
                    axios
                        .post(
                            SEND_SMS_ENDPOINT,
                            {
                                phone,
                                name
                            },
                            {
                                headers: {
                                    Authorization: data1?.token
                                }
                            }
                        )
                        .then(() => {
                            dispatch(addNotification(notifications.alreadySent(t('alreadySent'))));
                            setTime(60);
                            startTimer();
                            setResendAttempts((prevAttempts) => prevAttempts + 1);
                        })
                        .catch((error) => {
                            console.error(`Error: ${error}`);
                            dispatch(
                                addNotification(
                                    notifications.errorMessage(t('smsCodeSendError'))
                                )
                            );
                        });
                } else {
                    const requestData = {
                        email,
                        name
                    };
                    axios
                        .post(SEND_EMAIL_ENDPOINT, requestData, {
                            headers: {
                                Authorization: data1?.token
                            }
                        })
                        .then(() => {
                            //later may be updated
                            dispatch(addNotification(notifications.alreadySent(t('alreadySent'))));
                            setTime(60);
                            startTimer();
                            setResendAttempts((prevAttempts) => prevAttempts + 1);
                        })
                        .catch((error) => {
                            console.error(`Error: ${error}`);
                            dispatch(
                                addNotification(
                                    notifications.errorMessage(t('emailCodeSendError'))
                                )
                            );
                        });
                }
            } else {
                dispatch(addNotification(notifications.errorMessage(INFORM_USER)));
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handleChangeEmail = () => {
        dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
    };

    const switchVerificationType = () => {
        setVerificationType((prevType) => (prevType === "email" ? "phone" : "email"));
        if (!otpSent && data1?.token) {
            const requestData = {
                email,
                name
            };
            axios
                .post(SEND_EMAIL_ENDPOINT, requestData, {
                    headers: {
                        Authorization: data1?.token
                    }
                })
                .then(() => {
                    dispatch(addNotification(notifications.alreadySent(t('alreadySent'))));
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
            setOtpSent(true);
        }
    };

    switch (phase) {
        case EngagementPhase.Confirmation:
            return (
                <div className="confirmation-container text_white" style={{ overflow: "auto" }}>
                    <NotificationBar />
                    <div className="container">
                        <header>
                            <IoIosCheckmark />
                        </header>
                        <h4 className="text_white">{t("otpVerificationTitle")}</h4>
                        <div className="enve">
                            {verificationType === "email" ? (
                                <p>
                                    {t("otpSentEmail")} <br />
                                    <b>{email}.</b>
                                </p>
                            ) : (
                                <p>
                                    {t("otpSentSMS")} <br />
                                    <b>{phone}.</b>
                                </p>
                            )}
                            <p>{t("enterOTP")}</p>
                        </div>
                        <form>
                            <div className="input-field">
                                {Array.from({ length: 4 }, (_, index) => (
                                    <input
                                        type="number"
                                        key={index}
                                        ref={(el) => (inputsRef.current[index] = el as HTMLInputElement)}
                                        value={verificationCode[index] || ""}
                                        onChange={(e) => handleChange(e, index)}
                                        onKeyDown={(e) => handleBackspace(e, index)}
                                        maxLength={1}
                                    />
                                ))}
                            </div>
                            <p style={{ textAlign: 'center' }}>
                                {t('resendOTPIn')} {time}
                                <a href="#" onClick={handleResend} className={`resend-link ${time ? 'active' : ''} text_link`}>
                                    {t('resend')}
                                </a>
                            </p>
                            <button
                                type="button"
                                className={verificationCode.length === 4 ? "active" : ""}
                                onClick={handleVerify}
                            >
                                {t("verifyOTP")}
                            </button>
                            <button type="button" className="active" onClick={handleChangeEmail}>
                                {verificationType === "email" ? t("changeEmail") : t("changeNumber")}
                            </button>
                        </form>
                        <div
                            className="bottom-text"
                            style={{
                                textAlign: "center",
                                marginTop: "0px",
                                color: "#FFF"
                            }}
                        >
                            <p>
                                {t("changeVerificationType")}
                                <a href="#" className="change-verification-link text_link" onClick={switchVerificationType}>
                                    {t("clickHere")}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            );
        case EngagementPhase.PreEngagementForm:
        default:
            return (
                <div className="pre_engagement_container align_self_center bg_info">
                    <Header perEng={true} />
                    <NotificationBar />
                    <Box as="form" data-test="pre-engagement-chat-form" onSubmit={handleSubmit} {...formStyles}>
                        <Text {...titleStyles} as="h3">
                            {t("welcomeMessage.title")}
                        </Text>
                        <Text {...introStyles} as="p">
                            {t("welcomeMessage.intro")}
                            <br />
                            {t("welcomeMessage.desc")}
                            <br />
                            {t("welcomeMessage.beforeStart")}
                        </Text>

                
                        {/* Render content based on ref_code */}
                        {/* {refCode ? (
                            <div>
                                <Label htmlFor="ref_code" className="text_form_label">
                                    Reference Code: {refCode}
                                </Label>
                                <Input
                                    type="hidden"
                                    name="ref_code"
                                    value={refCode}
                                    onChange={(e) => dispatch(updatePreEngagementData({ ref_code: e.target.value }))}
                                    required
                                />
                            </div>
                        ) : null}
*/}

                        <Box {...fieldStyles} className="login_input_sec">
                            <Label htmlFor="name" className="text_form_label">{t("nameLabel")}</Label>
                            <Input
                                type="text"
                                placeholder={t("namePlaceholder")}
                                name="name"
                                data-test="pre-engagement-chat-form-name-input"
                                value={name}
                                onChange={(e) => dispatch(updatePreEngagementData({ name: e.target.value }))}
                                required

                            />
                        </Box>
                        <Box {...fieldStyles} className="login_input_sec">
                            <Label htmlFor="email" className="text_form_label">{t("emailLabel")}</Label>
                            <Input
                                type="email"
                                placeholder={t("emailPlaceholder")}
                                name="email"
                                data-test="pre-engagement-chat-form-email-input"
                                value={email}
                                onChange={(e) => dispatch(updatePreEngagementData({ email: e.target.value }))}
                                required

                            />
                        </Box>

                        <Box {...fieldStyles} className="login_input_sec">
                            <Label htmlFor="phone" className="text_form_label">{t("phoneLabel")}</Label>
                            <Input
                                type="text" // Change type to text to allow any characters for phone input
                                placeholder={t("phonePlaceholder")}
                                name="phone"
                                data-test="pre-engagement-chat-form-query-textarea"
                                value={phone}
                                onChange={(e) => {
                                    const newPhoneNumber = e.target.value;
                                    dispatch(updatePreEngagementData({ phone: newPhoneNumber }));
                                    validatePhoneNumber(newPhoneNumber);
                                }}
                                required
                            />
                            {phoneNumberError && <p style={{ color: "red" }}>{phoneNumberError}</p>}
                        </Box>

                        <Button variant="primary" type="submit" data-test="pre-engagement-start-chat-button">
                            {t("startChatButton")}
                        </Button>
                    </Box>
                </div>
            );
    }
};
