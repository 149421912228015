import React, { useState } from 'react';
import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";
import { useTranslation } from 'react-i18next';

import { containerStyles, titleStyles } from "./styles/Header.styles";
import "./styles/header.css"

type HeaderProps = {
    customTitle?: string;
    perEng?: boolean;
};

export const Header: React.FC<HeaderProps> = ({ customTitle, perEng }) => {
    const { t, i18n } = useTranslation('global');
    const [isChecked, setIsChecked] = useState(perEng);

    const handleLanguageToggle = () => {
        setIsChecked(!isChecked);
        const newLanguage = !isChecked ? 'en' : 'my'; // Change 'my' to your Malay language code
        i18n.changeLanguage(newLanguage);
    };

    return (
        <Box as="header" {...containerStyles} className="bg_light_blue">
            <Text as="h2" {...titleStyles}>
                {customTitle || t('liveChat')}
            </Text>
            {
                perEng && (
                    <div className="lang_section">
                        <div className="lang_text">{t('select_language')}</div>
                        <div className="switch">
                            <input
                                id="language-toggle"
                                className="check-toggle check-toggle-round-flat"
                                type="checkbox"
                                checked={isChecked}
                                onChange={handleLanguageToggle}
                            />
                            <label htmlFor="language-toggle" />
                            <span className="on">BM</span>
                            <span className="off">EN</span>
                        </div>
                    </div>
                )
            }
        </Box >
    );
};
