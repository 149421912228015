import React, { useEffect, useState } from 'react';
import { Box } from "@twilio-paste/core/box";
import { ChatIcon } from "@twilio-paste/icons/esm/ChatIcon";
import { ChevronDownIcon } from "@twilio-paste/icons/esm/ChevronDownIcon";

import { useDispatch, useSelector } from "react-redux";

import { changeExpandedStatus } from "../store/actions/genericActions";
import { AppState } from "../store/definitions";
import { getContainerStyles } from "./styles/EntryPoint.styles";

export const EntryPoint = () => {
    const dispatch = useDispatch();
    // const { expanded } = useSelector(({ session }: AppState) => ({
    //     expanded: session.expanded
    // }));

    // const [expanded, setExpanded] = useState<boolean>(false);

    const [localExpanded, setLocalExpanded] = useState<boolean>(true);
    const expanded = useSelector(({ session }: AppState) => session.expanded);

    // console.log("------> ", localExpanded, expanded)

    useEffect(() => {
        setLocalExpanded(expanded);
    }, [expanded]);
    // console.log("======> ", localExpanded, expanded)

    const handleClick = () => {
        dispatch(changeExpandedStatus({ expanded: !localExpanded }));
    };

    // useEffect(() => {
    //     const { expanded }: { expanded: boolean } = useSelector(({ session }: AppState) => ({
    //         expanded: session.expanded
    //     }));
    //     setExpanded(expanded); // Update the local state with the value from Redux
    // }, []);

    // const handleClick = () => {
    //     dispatch(changeExpandedStatus({ expanded: !expanded }));
    // };

    const containerStyles = getContainerStyles({ isExpand: expanded });

    return (
        <Box
            as="button"
            data-test="entry-point-button"
            onClick={handleClick}
            {...containerStyles}
            // style={{ visibility: 'visible' }}
            className={expanded ? "d-none" : "bg_theme_gradient gradient_ani d-none"}
        >
            {expanded ? (
                <ChevronDownIcon decorative={false} title="Minimize chat" size="sizeIcon80" className="" />
            ) : (
                <div className="chat_btn">
                    <span>Ask Anika</span>
                    <ChatIcon decorative={false} title="Open chat" size="sizeIcon60" />
                </div>
            )}
        </Box>
    );
};