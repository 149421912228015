/* eslint-disable @typescript-eslint/no-empty-function */

import React, { useState, useEffect  } from 'react'
import './styles/button.css'

type Props = { 
  onClick?: () => void;
  messageDis?: boolean; // Change the type to boolean directly
}

const AppButton: React.FC<Props> = ({ children, onClick, messageDis }) => {
  const [isDisabled, setIsDisabled] = useState(messageDis);
  const [remainingTime, setRemainingTime] = useState(0);

  const handleClick = () => {
      if (!isDisabled && onClick) {
          setIsDisabled(true); // Disable the button
          onClick();
      }
  };

  useEffect(() => {
    if (messageDis) {
      setRemainingTime(30);

      const timerId = setInterval(() => {
        setRemainingTime((prevTime) => {
          const newTime = prevTime - 1;

          if (newTime === 0) {
            setIsDisabled(false);
            clearInterval(timerId);
          }

          return newTime;
        });
      }, 1000);

      // Clean up the timer to avoid memory leaks
      return () => {
        clearInterval(timerId);
        setRemainingTime(0);
      };
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {
      console.log("Cleanup function executed");
    };
  }, [messageDis]);

  return (
      <button
          type="button"
          className="button2"
          onClick={handleClick}
          disabled={isDisabled}
          style={{
              margin: `8px 0px 2px 10px`
          }}
      >
          {messageDis && remainingTime > 0
        ? `Checking available in ${remainingTime}s` : children}
      </button>
  )
}


export default AppButton
