import { BoxStyleProps } from "@twilio-paste/core/box";


interface CalculateContainerSizeProps {
    isExpand: boolean
}

const calculateContainerSize = ({ isExpand }: CalculateContainerSizeProps): BoxStyleProps => {
    let width;
    let height;
    let borderRadius = {}

    if (isExpand) {
        width = `150px`;
        height = `2.5rem`;
        borderRadius = {
            borderTopRightRadius: "borderRadius30",
            borderTopLeftRadius: "borderRadius30",
        }
    } else {
        width = `2rem`
        height = `2rem`
        borderRadius = {
            borderRadius: "borderRadiusCircle"
        }
    }

    return {
        width: width,
        height: height, // `90vh`,
        marginBottom: isExpand ? "space0" : "space30",
        ...borderRadius,
    };
};

export const getContainerStyles = ({ isExpand }: CalculateContainerSizeProps): BoxStyleProps => {
    return {
        border: "none",
        backgroundColor: "colorBackgroundPrimary",
        display: "flex",
        ...calculateContainerSize({ isExpand }),
        fontSize: "fontSize50",
        alignItems: "center",
        justifyContent: "center",
        color: "colorTextWeakest",
        cursor: "pointer",
        transition: "background-color 0.2s",
        outline: "0px",
        padding: "space0",
        _hover: {
            backgroundColor: "colorBackgroundPrimaryStronger"
        },
        _focusVisible: {
            backgroundColor: "colorBackgroundPrimaryStronger",
            boxShadow: "shadowFocus"
        }
    };
};