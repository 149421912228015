import React, { useState } from 'react';
import './styles/button.css';

type Props = { 
    onClick?: () => void;
    isSelected?: boolean; // Add isSelected prop
}


const SelectableButton: React.FC<Props> = ({ onClick, isSelected, children }) => {
    const [isButtonSelected, setIsButtonSelected] = useState(isSelected || false);

    const handleButtonClick = () => {
        setIsButtonSelected(!isButtonSelected); // Toggle selection state
        if (onClick) {
            onClick();
        }
    };

    return (
        <button
            type="button"
            className={`button2 ${isButtonSelected ? 'selected' : ''}`}
            style={{ margin: `8px 0px 2px 10px` }}
            onClick={handleButtonClick}
        >
            {children}
        </button>
    );
};


export default SelectableButton;
