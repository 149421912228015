import { Notification } from "./store/definitions";

const shortenFileName = (string: string, maxChar = 50) => {
    const [, filename, fileExtension] = string.match(/^(.+)(\.[\S]*)$/) || [];

    return `${filename.substr(0, maxChar)}[...]${fileExtension || ""}`;
};


const fileAttachmentAlreadyAttachedNotification = ({ fileName, err }: { fileName: string, err: string }): Notification => ({
    id: `FileAttachmentAlreadyAttachedNotification_${Math.random()}`,
    dismissible: true,
    message: `${shortenFileName(fileName)} ${err}`,
    type: "error",
    timeout: 10000
});

const fileAttachmentInvalidSizeNotification = ({
    fileName,
    err
}: {
    fileName: string;
    err: string;
}): Notification => ({
    id: `FileAttachmentInvalidSizeNotification_${Math.random()}`,
    dismissible: true,
    message: `${shortenFileName(
        fileName
    )} ${err}`,
    type: "error",
    timeout: 10000
});

const fileAttachmentInvalidTypeNotification = ({ fileName, err }: { fileName: string; err: string; }): Notification => ({
    id: `FileAttachmentInvalidTypeNotification_${Math.random()}`,
    dismissible: true,
    message: `${shortenFileName(
        fileName
    )} ${err}`,
    type: "error",
    timeout: 10000
});

const fileDownloadInvalidSizeNotification = ({
    fileName,
    maxFileSize,
    err
}: {
    fileName: string;
    maxFileSize: string;
    err: string;
}): Notification => ({
    id: `FileDownloadInvalidSizeNotification_${Math.random()}`,
    dismissible: true,
    message: `${shortenFileName(
        fileName
    )} ${err} ${maxFileSize}`,
    type: "error",
    timeout: 10000
});

const fileDownloadInvalidTypeNotification = ({ fileName, err }: { fileName: string; err: string; }): Notification => ({
    id: `FileDownloadInvalidTypeNotification_${Math.random()}`,
    dismissible: true,
    message: `${shortenFileName(fileName)} ${err}`,
    type: "error",
    timeout: 10000
});

const noConnectionNotification = (err: string): Notification => ({
    id: "NoConnectionNotification",
    dismissible: true,
    message: err,
    type: "warning"
});

const codeIncorrectNotification = (err: string): Notification => ({
    id: `codeIncorrectNotification`,
    dismissible: true,
    message: err,
    type: "error"
});

const errorMessage = (err: string): Notification => ({
    id: `errorMessage`,
    dismissible: true,
    message: `${err}`,
    type: "error",
    timeout: 5000
});

const alreadySent = (error: string): Notification => ({
    id: "alreadyExist",
    dismissible: true,
    message: error,
    type: "neutral",
    timeout: 10000
});

const failedToInitSessionNotification = (error: string): Notification => ({
    id: `FailedToInitSessionNotification`,
    dismissible: true,
    message: error,
    type: "error"
});

export const notifications = {
    errorMessage,
    fileAttachmentAlreadyAttachedNotification,
    fileAttachmentInvalidSizeNotification,
    fileAttachmentInvalidTypeNotification,
    fileDownloadInvalidSizeNotification,
    fileDownloadInvalidTypeNotification,
    noConnectionNotification,
    codeIncorrectNotification,
    alreadySent,
    failedToInitSessionNotification
};
