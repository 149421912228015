import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import { CustomizationProvider, CustomizationProviderProps } from "@twilio-paste/core/customization";
import { CSSProperties, FC, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import { RootContainer } from "./RootContainer";
import { AppState, EngagementPhase } from "../store/definitions";
import { sessionDataHandler, getStoredEmail, getStoredPhone } from "../sessionDataHandler";
import { initSession } from "../store/actions/initActions";
import { changeEngagementPhase, updatePreEngagementData } from "../store/actions/genericActions";
import { changeExpandedStatus } from "../store/actions/genericActions";

import NoticeIcon from '../assets/img/privacy_notice_icon.png'
import IntroVideo from '../assets/video/intro_video.mp4';
import IntroVideoMobile from '../assets/video/intro_video_mobile.mp4';

const AnyCustomizationProvider: FC<CustomizationProviderProps & { style: CSSProperties }> = CustomizationProvider;

/* Sx Add */
import "./styles/customCss.css";

export function WebchatWidget() {
    const theme = useSelector((state: AppState) => state.config.theme);
    const dispatch = useDispatch();
    const data = sessionDataHandler.tryResumeExistingSession();
    const API_BASE_URL = sessionDataHandler.getEndpoint();
    const CHECK_CODE_ENDPOINT = `${API_BASE_URL}/check-stage`;
    const email = getStoredEmail();
    const phone = getStoredPhone();
    const { t } = useTranslation("global");

    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const [videoSource, setVideoSource] = useState('');
    const [isOpen, modalIsOpened] = useState<boolean>(true);

    const openModal = () => {
        modalIsOpened(true);
    };

    const closeModal = () => {
        modalIsOpened(false);
    };

    useEffect(() => {
        const resizeRenderVideo = () => {
            const windowWidth = window.innerWidth;
            // Choose video source based on window width
            if (windowWidth <= 768) {
                setVideoSource(IntroVideoMobile);
            } else {
                setVideoSource(IntroVideo);
            }
        };

        resizeRenderVideo();

        // Add event listener for window resize
        window.addEventListener('resize', resizeRenderVideo);

        async function fetchData() {
            if (data && email && phone) {
                try {
                    const res = await axios.post(CHECK_CODE_ENDPOINT, { email, phone }, {
                        headers: {
                            'Authorization': data?.token
                        }
                    })
                    if (res.status === 400) {
                        dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
                    } else if (res.status === 200) {
                        dispatch(initSession({ token: data.token, conversationSid: data.conversationSid, engPhase: EngagementPhase.MessagingCanvas, translation: { t } }));
                    } else {
                        dispatch(updatePreEngagementData({ email: "", name: "", phone: "" }));
                        if (res.status === 403) {
                            dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
                        } else if (res.status === 402) {
                            dispatch(initSession({ token: data.token, conversationSid: data.conversationSid, engPhase: EngagementPhase.MessagingCanvas, translation: { t } }));
                        } else if (res.status === 500) {
                            dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
                        } else {
                            dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
                        }
                    }
                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        if (error.response?.status === 400) {
                            dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
                        } else {
                            dispatch(updatePreEngagementData({ email: "", name: "", phone: "" }));
                            if (error.response?.status === 403) {
                                dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
                            } else if (error.response?.status === 402) {
                                dispatch(initSession({ token: data.token, conversationSid: data.conversationSid, engPhase: EngagementPhase.MessagingCanvas, translation: { t } }));
                            } else if (error.response?.status === 500) {
                                dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
                            } else {
                                dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
                            }
                        }
                    } else {
                        dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
                    }
                }
            } else {
                // if no token is stored, got engagement formy
                dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
            }
        }
        fetchData();
        // Cleanup: remove event listener when component unmounts
        return () => {
            window.removeEventListener('resize', resizeRenderVideo);
        };
    }, [dispatch]);

    const handleClick = () => {
        closeModal();
        const video = document.getElementById('intro_video') as HTMLVideoElement;
        if (video) {
            video.play();
            video.addEventListener('ended', function () {
                // To hide the video element
                video.remove();

                // OR to remove the video element from the DOM
                // video.remove();
            });
        }
    };

    const showDialog = () => {
        dispatch(changeExpandedStatus({ expanded: true }));
        const video = document.getElementById('intro_video') as HTMLVideoElement;
        video.style.display = "none";
        window.removeEventListener('ended', handleClick);
    }

    return (
        <AnyCustomizationProvider
            baseTheme={theme?.isLight ? "default" : "dark"}
            theme={theme?.overrides}
            elements={{
                MESSAGE_INPUT: {
                    boxShadow: "none!important" as "none"
                },
                MESSAGE_INPUT_BOX: {
                    display: "inline-block",
                    boxShadow: "none",
                    // background: "rgb(119, 176, 222, 0.33)",
                },
                ALERT: {
                    paddingTop: "space30",
                    paddingBottom: "space30"
                },
                BUTTON: {
                    color: "colorTextBrandInverse",
                    "&[aria-disabled='true'][color='colorTextLink']": {
                        // color: "colorTextLinkWeak",
                        color: "colorTextBrandInverse",
                    }
                }
            }}
            style={{ minHeight: "100%", minWidth: "100%" }}
        >
            {isOpen && (
                <div className="modal_mask">
                    <div className="modal_content">
                        <div className="modal_header justify_content_center align_items_center text_center">
                            <img src={NoticeIcon} alt="Notice Icon" className="img img_notice" />
                            <h5>Privacy Notice</h5>
                        </div>

                        <div className="modal_body justify_content_center">
                            <p>Protecting your privacy is important to us. We may gather and analyze personal data to enhance our services and deliver customized experiences.</p>
                            <p>Rest assured, we uphold strict security measures to safeguard your information. By engaging with ANIKA, you acknowledge and accept our commitment to privacy as delineated in our <a href="https://www.ibpo.com.my/privacy-policy/" target="_blank">Privacy Policy</a></p>
                        </div>

                        <div className="modal_footer justify_content_center">
                            <button type="button" className="btn btn_modal text-center" onClick={handleClick}></button>
                        </div>
                    </div>
                </div>
            )}
            <div className='lc_intro_video'>
                <video id="intro_video" src={videoSource} className='intro_video' autoPlay={false} controls={false} onEnded={showDialog}></video>
            </div>
            <RootContainer />
        </AnyCustomizationProvider>
    );
}
