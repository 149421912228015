import { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Header } from "./Header";
import { MessageList } from "./MessageList";
import { MessageInput } from "./MessageInput";
import { AppState } from "../store/definitions";
import { ConversationEnded } from "./ConversationEnded";
import { NotificationBar } from "./NotificationBar";
import { removeNotification } from "../store/actions/genericActions";
import { notifications } from "../notifications";
import { AttachFileDropArea } from "./AttachFileDropArea";
import ProgressBar from "./ProgressBar"

interface MessageCanvasAttributes {
  className?: string;
}

export const MessagingCanvasPhase = () => {
  const { t } = useTranslation("global");
  const dispatch = useDispatch();
  const conversationState = useSelector((state: AppState) => state.chat.conversationState);
  const [isSending, setIsSending] = useState(false);
  const [whichStage, setWhichStage] = useState(0);
  const [myStageItems, setMyStageItems] = useState<string[]>([]);

  useEffect(() => {
    dispatch(removeNotification(notifications.failedToInitSessionNotification("ds").id));
  }, [dispatch]);

  const Wrapper = conversationState === "active" ? AttachFileDropArea : Fragment;

  return (
    <div className="engagement_bg">
      <Wrapper>
        <Header />
        <NotificationBar />
        <ProgressBar whichStage={whichStage} myStageItems={myStageItems} name={t("progressBarName")} />
        <MessageList setIsSending={setIsSending} setWhichStage={setWhichStage} setMyStageItems={setMyStageItems} />
        {conversationState === 'active' ? (
          <MessageInput isSending={isSending} setIsSending={setIsSending} />
        ) : (
          <ConversationEnded />
        )}
      </Wrapper>
    </div>
  );
};
