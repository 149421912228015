import React from 'react';
import './styles/ProgressBar.css'; // Import your CSS file here

interface ProgressBarProps {
  whichStage: number;
  myStageItems: string[]; // Replace SomeType with the appropriate type // Define the function type
  name: string
}

const ProgressBar: React.FC<ProgressBarProps> = ({whichStage, myStageItems, name}) => {
    const stages = myStageItems;

    if(myStageItems.length > 1){
      return (
        <section>
          {/* <h2>{name}</h2> */}
          <ol className="progress-bar">
            {stages.map((stage, index) => (
              <li
                key={index}
                className={`${
                  //later may be updated
                  index < whichStage ? 'is-complete' : index === whichStage ? 'is-active' : ''
                }`}
              >
                <span>{stage}</span>
              </li>
            ))}
          </ol>
          {/* <div className="button-container">
            <button onClick={handlePreviousStage} disabled={activeStage === 0}>
              Previous
            </button>
            <button onClick={handleNextStage} disabled={activeStage === stages.length - 1}>
              Next
            </button>
          </div>  */}
        </section>
      );
    }
    return null;
  };
  
  export default ProgressBar;