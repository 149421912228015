import { BoxStyleProps } from "@twilio-paste/core/box";

interface CalculateContainerSizeProps {
  height: number;
  width: number;
}

const calculateContainerSize = ({ height, width }: CalculateContainerSizeProps): BoxStyleProps => {
  const maxHeight1 = 1000;
  const maxHeight2 = 690;

  // let width;
  let calculatedHeight;
  /* console.log(height); */
  /* console.log(width, 'width > 425: ', (width > 425 ? `95vw` : `90vw`), (width > 425)); */

  // if (height >= maxHeight2 && height <= maxHeight1) {

  //   if(width <= 325){
  //     width = 300;
  //     calculatedHeight = 620;
  //   }else if(width <= 375){
  //     width = 330;
  //     calculatedHeight = 776;
  //   }else if(width <= 425) {
  //     width = 350;
  //     calculatedHeight = 780;
  //   }else if(width <= 768) {
  //     width = 400;
  //     calculatedHeight = 900;
  //   }else{
  //     // width = 320;
  //     width = 850;
  //     calculatedHeight = 590;
  //   }
  // } else if (height < maxHeight2) {

  //   if(width <= 325){
  //     width = 300;
  //     calculatedHeight = 620;
  //   }else if(width <= 375){
  //     width = 330;
  //     calculatedHeight = 776;
  //   }else if(width <= 425) {
  //     width = 350;
  //     calculatedHeight = 780;
  //   }else if(width <= 768) {
  //     width = 400;
  //     calculatedHeight = 900;
  //   }else{
  //     // width = 292.881355932;
  //     width = 800
  //     calculatedHeight = 700;
  //   }
  // } else {

  //   if(width <= 325){
  //     width = 300;
  //     calculatedHeight = 620;
  //   }else if(width <= 375){
  //     width = 330;
  //     calculatedHeight = 776;
  //   }else if(width <= 425) {
  //     width = 350;
  //     calculatedHeight = 780;
  //   }else if(width <= 768) {
  //     width = 400;
  //     calculatedHeight = 900;
  //   }else{
  //     // width = 357.966101695;
  //     width = 800
  //     calculatedHeight = 960;
  //   }
  //   // calculatedHeight = height;

  // }

  return {
    // width: `${width}px`,
    // height: `${calculatedHeight}px`,
    width: (width > 427 ? `95vw` : `90vw`),
    height: (width > 427 ? (width > 1024 ? `92vh` : `88vh`) : (height > 670 ? `90vh` : `85vh`)), // `90vh`,
    marginBottom: width > 427 ? "space30" : "space10",
  };
};

export const getOuterContainerStyles = (): BoxStyleProps => {
  return {
    position: "fixed",
    bottom: "50%", /* If display back entry point button change to space0 */
    right: "50%",
    // bottom: "space30", /* If display back entry point button change to space0 */
    // right: "space60",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    transform: "translate(50%, 50%)",
    transition: "display 10s ease-out"
  };
};

export const getInnerContainerStyles = ({ height, width }: CalculateContainerSizeProps): BoxStyleProps => {
  return {
    boxShadow: "shadow",
    display: "flex",
    flexDirection: "column",
    ...calculateContainerSize({ height, width }),
    // marginBottom: "space30",
    // borderRadius: "borderRadius30",
    // backgroundColor: "colorBackgroundBody",
    // ... other styles
  };
};

