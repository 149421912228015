import { Media, Message } from "@twilio/conversations";
import { Box } from "@twilio-paste/core/box";
import { ScreenReaderOnly } from "@twilio-paste/core/screen-reader-only";
import { useSelector } from "react-redux";
import { Text } from "@twilio-paste/core/text";
import { Flex } from "@twilio-paste/core/flex";
import { Key, KeyboardEvent, useEffect, useRef, useState } from "react";
import { SuccessIcon } from "@twilio-paste/icons/esm/SuccessIcon";
import { QRCodeCanvas } from 'qrcode.react';

import { AppState } from "../store/definitions";
import { FilePreview } from "./FilePreview";
import { parseMessageBody } from "../utils/parseMessageBody";
import {
    getAvatarContainerStyles,
    getInnerContainerStyles,
    authorStyles,
    timeStampStyles,
    bodyStyles,
    outerContainerStyles,
    readStatusStyles,
    bubbleAndAvatarContainerStyles
} from "./styles/MessageBubble.styles";
import AvatarImage from "../assets/img/anika_avatar.png";
import ChatEmojiThumbs from "../assets/img/chat_emoji/thumbs_up.png";

const doubleDigit = (number: number) => `${number < 10 ? 0 : ""}${number}`;

interface MessageAttributes {
    items: string[] | undefined;
    progressItems: string[] | undefined;
    stage: number | undefined;
    multiButtons: boolean | undefined;
    end: number | undefined;
    url: string | undefined;
    numberOfSelectable: number | undefined;
    disabless: string | undefined;
    emoji: string | undefined;
    qr_code_url: string | undefined;
}

export const MessageBubble = ({
    message,
    isLast,
    isLastOfUserGroup,
    focusable,
    updateFocus,
    gifUrl
}: {
    message: Message;
    isLast: boolean;
    isLastOfUserGroup: boolean;
    focusable: boolean;
    updateFocus: (newFocus: number) => void;
    gifUrl?: string | null | undefined;  // Accept GIF URL as a prop
}) => {
    const [read, setRead] = useState(false);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const { conversationsClient, participants, users, fileAttachmentConfig } = useSelector((state: AppState) => ({
        conversationsClient: state.chat.conversationsClient,
        participants: state.chat.participants,
        users: state.chat.users,
        fileAttachmentConfig: state.config.fileAttachment
    }));
    const messageRef = useRef<HTMLDivElement>(null);

    const belongsToCurrentUser = message.author === conversationsClient?.user.identity;

    useEffect(() => {
        if (isLast && participants && belongsToCurrentUser) {
            const getOtherParticipants = participants.filter((p) => p.identity !== conversationsClient?.user.identity);
            setRead(
                Boolean(getOtherParticipants.length) &&
                getOtherParticipants.every((p) => p.lastReadMessageIndex === message.index)
            );
        } else {
            setRead(false);
        }
    }, [participants, isLast, belongsToCurrentUser, conversationsClient, message]);

    useEffect(() => {
        if (focusable) {
            messageRef.current?.focus();
        }
    }, [focusable]);

    // const renderEmoji = async (emoji: string) => {
    const renderEmoji = (emoji: string | undefined) => {
        // console.log("Emoji Link", ChatEmojiThumbs)
        // try {
        //     const { default: emojiName } = await import(`../assets/img/chat_emoji/${emoji}`);

        return (
            <>
                <div style={{ marginBottom: '10px' }} />
                <img
                    src={ChatEmojiThumbs}
                    // src={emoji}
                    alt="Emoji"
                    style={{
                        maxWidth: '50px',
                        maxHeight: '50px',
                        display: 'block',
                        margin: '0 auto',

                    }}
                />
            </>
        );
        // } catch (error) {
        //     console.error("Error rendering emoji:", error);
        //     return null; // Return null or handle error accordingly
        // }
    }
    const renderQR = (qr_code_url: string | undefined) => {
        return (
            <>
                <div style={{ marginBottom: '10px' }} />
                <div style={{ textAlign: 'center' }}>
                    {qr_code_url && (
                        <QRCodeCanvas
                            value={qr_code_url}
                            style={{
                                border: '3px solid #000',
                                padding: '2px',
                                display: 'inline-block'
                            }}
                        />
                    )}
                </div>
            </>
        );
        // } catch (error) {
        //     console.error("Error rendering emoji:", error);
        //     return null; // Return null or handle error accordingly
        // }
    }

    const renderMedia = () => {
        if (fileAttachmentConfig?.enabled) {
            if (!message.attachedMedia) {
                return null;
            }

            return message.attachedMedia.map((media: Media, index: Key) => {
                const file = {
                    name: media.filename,
                    type: media.contentType,
                    size: media.size
                } as File;
                return <FilePreview key={index} file={file} isBubble={true} media={media} focusable={focusable} />;
            });
        }

        return <i>Media messages are not supported</i>;
    };

    const renderGif = () => {
        if (gifUrl) {
            return (
                <>
                    <div style={{ marginBottom: '10px' }} />
                    <img
                        src={gifUrl}
                        alt="GIF"
                        style={{
                            maxWidth: '100%',
                            maxHeight: '200px',
                            display: 'block',
                            borderRadius: '20px',
                            margin: '0 auto',
                        }}
                    />
                </>
            );
        }

        return null;
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            const newFocusValue = message.index + (e.key === "ArrowUp" ? -1 : 1);
            updateFocus(newFocusValue);
        }
    };

    const handleMouseDown = () => {
        setIsMouseDown(true);
    };

    const handleMouseUp = () => {
        setIsMouseDown(false);
    };

    const handleFocus = () => {
        // Ignore focus from clicks
        if (!isMouseDown) {
            // Necessary since screen readers can set the focus to any focusable element
            updateFocus(message.index);
        }
    };

    const author = users?.find((u) => u.identity === message.author)?.friendlyName || message.author;

    return (
        <Box
            {...outerContainerStyles}
            tabIndex={focusable ? 0 : -1}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            ref={messageRef}
            data-message-bubble
            data-testid="message-bubble"
        >
            <Box {...bubbleAndAvatarContainerStyles}>
                {!belongsToCurrentUser && (
                    <Box {...getAvatarContainerStyles(!isLastOfUserGroup)} data-testid="avatar-container">
                        {isLastOfUserGroup && <img
                            src={AvatarImage}
                            // src="https://img.freepik.com/free-vector/surprised-talking-robot-speech-bubbles-chatbot-dialog-online-lesson_74855-103.jpg?w=826&t=st=1695191269~exp=1695191869~hmac=4b4a281caae40a9103b8def4ebadaad26240ca7fc68686b0ebead81290388d69"
                            alt="User Avatar"
                            width="50"
                            height="50"
                            className="img_avatar"
                        />}
                    </Box>
                )}
                <Box {...getInnerContainerStyles(belongsToCurrentUser)} className="chat_box_bg">
                    <Flex hAlignContent="between" width="100%" vAlignContent="center" marginBottom="space20">
                        <Text {...authorStyles} as="p" aria-hidden style={{ textOverflow: "ellipsis" }} title={author}>
                            {
                                (belongsToCurrentUser) ? author : 'Anika'
                            }
                        </Text>
                        <ScreenReaderOnly as="p">
                            {belongsToCurrentUser
                                ? "You sent at"
                                : `${users?.find((u) => u.identity === message.author)?.friendlyName} sent at`}
                        </ScreenReaderOnly>
                        <Text {...timeStampStyles} as="p">
                            {`${doubleDigit(message.dateCreated.getHours())}:${doubleDigit(
                                message.dateCreated.getMinutes()
                            )}`}
                        </Text>
                    </Flex>
                    {/* <QRCodeCanvas value="https://classic.yarnpkg.com/en/package/qrcode.react" /> */}

                    {message.attributes && (message.attributes as MessageAttributes).qr_code_url !== undefined ? renderQR((message.attributes as MessageAttributes).qr_code_url) : null}

                    {message.attributes && (message.attributes as MessageAttributes).emoji !== undefined ? renderEmoji((message.attributes as MessageAttributes).emoji) : null}
                    <Text as="div" {...bodyStyles}>
                        {<Text as="p" {...bodyStyles} className={!belongsToCurrentUser && isLast ? "" : ""}>{message.body ? parseMessageBody(message.body, belongsToCurrentUser) : null}</Text>}
                    </Text>
                    {renderGif()}
                    {message.type === "media" ? renderMedia() : null}
                </Box>
            </Box>
            {read && (
                <Flex hAlignContent="right" vAlignContent="center" marginTop="space20">
                    <Text as="p" {...readStatusStyles}>
                        Reads
                    </Text>
                    <SuccessIcon decorative={true} size="sizeIcon10" color="colorTextWeak" />
                </Flex>
            )}
        </Box>
    );
};
