import React, { useState, useEffect } from 'react';
import './styles/sliderImage.css'; // Add your CSS file for styling

interface SliderProps {
    desktopImages: string[];
    mobileImages: string[];
    interval?: number;
}

const Slider: React.FC<SliderProps> = ({ desktopImages, mobileImages, interval = 5500 }) => {
    const [images, setImages] = useState(desktopImages)
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        function handleResize() {
            const width = window.innerWidth;
            if (width <= 768) {
                setImages(mobileImages);
            } else {
                setImages(desktopImages);
            }
        }

        handleResize(); // Call once to set initial images

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [desktopImages, mobileImages]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImageIndex((prevIndex) =>
                prevIndex === images.length - 1 ? 0 : prevIndex + 1
            );
        }, interval);

        return () => clearInterval(intervalId);
    }, [images.length, interval]);

    const goToPrevious = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    const goToNext = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

    const goToImage = (index: number) => {
        setCurrentImageIndex(index);
    };

    return (
        <div className="slider_container">
            <button type="button" className="slider_control" onClick={goToPrevious}>
                P
            </button>
            <div className="slider_image_container">
                <img
                    className="slider_image"
                    src={images[currentImageIndex]}
                    alt={`Image ${currentImageIndex}`}
                />
            </div>
            <button type="button" className="slider-control" onClick={goToNext}>
                N
            </button>
            <div className="pagination_dots">
                {images.map((_, index) => (
                    <span
                        key={index}
                        className={`dot ${index === currentImageIndex ? 'active' : ''}`}
                        onClick={() => goToImage(index)}
                    />
                ))}
            </div>
        </div>
    );
};

export default Slider;
