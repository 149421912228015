import React from "react";
import { useTranslation } from 'react-i18next';

import { EngagementPhase } from "../store/definitions";

export const PhaseIntroText: React.FC<{ phase: EngagementPhase }> = ({ phase }) => {

    const { t } = useTranslation('global');

    switch (phase) {
        case EngagementPhase.Loading:
            return (
                <div>
                    <h3 className="text_intro_title_text">{t("introText.pleaseWait")}</h3>
                </div>
            );
        case EngagementPhase.MessagingCanvas:
            return (
                <div>
                    <h3 className="text_intro_title_text d-none">{t("introText.letsChat")}</h3>
                </div>
            );
        case EngagementPhase.PreEngagementForm:
        case EngagementPhase.Confirmation:
        default:
            return (
                <div>
                    <h3 className="text_intro_title d-none">{t("introText.helloAnika")}</h3>
                    <h5 className="text_intro_desc d-none">{t("introText.howAssist")}</h5>
                </div>
            );
    }

}
